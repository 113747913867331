/**
 * Will format using the Intl.NumberFormat package
 * (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat)
 *
 * This will exit fast if the Intl package is not present, or if the given number is null.
 */
export function numberFormat(
  n: number,
  type: 'number' | 'decimal' | 'currency' | 'percent' | 'unit' = 'decimal',
  decimals = 2,
  compact = false,
): string {
  if (n == null || !('Intl' in window)) {
    return ``;
  }
  const internalType = type === 'number' ? 'decimal' : type;
  if (internalType === 'percent') {
    n = n / 100;
  }

  if (!compact || n < 1000) {
    // No compacting. Make the number pretty
    try {
      return new Intl.NumberFormat('nb-NO', {
        style: internalType,
        currency: 'NOK',
        ...(type != 'number'
          ? {
              minimumFractionDigits: decimals,
              maximumFractionDigits: decimals,
            }
          : {}),
      }).format(n);
    } catch (ex) {
      console.error(ex);
      return `${n}`;
    }
  }

  // When compact is true, we disregard the decimals and make the number pretty by adding a postfix behind the number
  const numericSymbols = ['E', 'P', 'T', 'G', 'M', 'k'];
  return numericSymbols.reduce((ret: string, sym: string, idx: number): string => {
    const multi = Math.pow(1000, numericSymbols.length - idx);
    return +ret >= multi ? n / multi + sym : ret;
  }, '' + n);
}

export function NOKToOre(value: number, decimals = `${value}`.split('.')[1]?.length ?? 2): string {
  return `${(value * 100).toFixed(decimals)}`;
}
export function OreToNOK(value: number, decimals = (`${value}`.split('.')[1]?.length ?? 0) + 2): string {
  return `${(value / 100).toFixed(decimals)}`;
}
